/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */

declare var angular: angular.IAngularStatic;

import * as bowser from 'bowser';
import * as moment from 'moment-timezone';

import { UpdateHouseIdDialogController } from './updateHouseIdDialog-controller';
import { StatusConstant } from '../../constants/status.constant';
import { RequestCustomizationDialog } from './dialogs/requestCustomizationDialog';
import { OperationsDestinationDialogController } from './operationsDestinationDialog-controller';
import { TCDialogController } from './tcDialog-controller';
import { TrafficReviewDialogController } from './trafficReviewDialog-controller';

export const deliveryDetail = {
    selector: 'deliveryDetail',
    bindings: {},
    template: require('./deliveryDetail-template.html'),
    controller: class DeliveryDetailController {

        public gettingAssetUuids: boolean = false;
        public displayError: boolean = false;
        public statusConstants: any;
        public _sessionData: any;
        public delivery:any = {};
        public deliveriesStatuses: any;
        public orderItemAsset: any = {};
        public orderItemAssetDelivery: any = {};
        public assetMap: any = null;
        public proxyAsset: any = null;
        public asset: any = null;
        public isAdmin: boolean;
        public downloadLink: string;
        public loading = {
            spot: false,
            original: false,
            dist: false,
            mezz: false,
            qt: false,
            mp3: false,
            wmv: false,
            vqc: false,
            traffic: false,
        };
        public canDownload = {
            deliveredSpot: false,
            originalSpot: false,
            mezzanine: false,
            quicktimeProxy: false,
            mp3: false,
            wmv: false,
            proxy: false,
            slate: false,
            qcReport: false,
            metadata: false,
            traffic: false,
        };
        public storyBoard: any = [];
        public deliveryDuration: any = '';
        public uploadStartDate: any = '';
        public runStartDate: any = '';
        public runEndDate: any = '';
        public hostnames: string = '';
        public statusService: any;
        public termsAndConditions: any = null;
        // This will be toggled true only if there are rights dates to display
        public showMultiRightsDates: boolean = false;
        public isFranchise: boolean;
        public spotDeliveryFilename: any = null;
        public showThumbnailStrip: boolean = false;
        public proxyType: string = '';

        static get $inject() {
            return [
                '$scope',
                '$q',
                '$rootScope',
                '$state',
                '$stateParams',
                '$window',
                '$mdDialog',
                '$filter',
                'DeliveryResourceFactory',
                '$timeout',
                'NotificationService',
                'AssetResourceFactory',
                'loginService',
                'AuthenticationResourceFactory',
                'EndPointService',
                'StatusService',
                'TermsAndConditionsResourceFactory',
                'DestinationResourceFactory',
                'EndPointService',
            ];
        }

        constructor(
            private $scope: any,
            private $q: any,
            private $rootScope: any,
            private $state: any,
            private $stateParams: any,
            private $window: any,
            private $mdDialog: any,
            private $filter: any,
            private DeliveryResourceFactory: any,
            private $timeout: any,
            private NotificationService: any,
            private AssetResourceFactory: any,
            private loginService: any,
            private AuthenticationResourceFactory: any,
            private EndPointService: any,
            private StatusService: any,
            private TermsAndConditionsResourceFactory: any,
            private DestinationResourceFactory: any,
            private ResourceEndPointService: any
        ) {
            this.statusConstants = new StatusConstant();

            /* PRIVATE : DATA */
            //Declare all private variables here
            /*
             * This is the `vm` object. It is a direct reference to the controller
             * which acts as our 'view-model' in angular. It also limits our need
             * to be accessing $scope directly. */
            /*jshint validthis:true*/
            this._sessionData = this.loginService.getSessionData();

            /* BINDABLE : DATA */
            this.deliveriesStatuses = this.statusConstants.deliveries;
            this.deliveriesStatuses.COMPLETED.status = 'Delivered';
            this.isAdmin = this.loginService.isAdmin();
            this.downloadLink = this.EndPointService.assetDownloadEndpoint;
            this.statusService = this.StatusService;
            this.isFranchise = this._sessionData.franchiseCustomer;

            /* EVENTS */
            //Register any event listeners
        }

        /* IMPLEMENTATION : BINDABLE */
        async downloadAsset(uuid:string, type:string, alias:string) {
            let vm = this;

            if (uuid === undefined) {
                return;
            }

            // Start handling dates
            let today = new Date();
            let latestRightDate = new Date();
            let futureDatesFound = '<ul>';
            
            // Find the most future right date
            if(vm.delivery.Spot.RightsDates && (vm.delivery.Spot.RightsDates.BROADCAST || vm.delivery.Spot.RightsDates.DIGITAL || vm.delivery.Spot.RightsDates.NEWMEDIA || vm.delivery.Spot.RightsDates.INDUSTRIAL)) {
                for (const [key] of Object.entries(vm.delivery.Spot.RightsDates)) {
                    if(new Date(vm.delivery.Spot.RightsDates[key].from) > latestRightDate) {
                        latestRightDate = new Date(vm.delivery.Spot.RightsDates[key].from);
                    }
                    if(new Date(vm.delivery.Spot.RightsDates[key].from) > today) {
                        futureDatesFound += '<li>'
                            + (new Date(vm.delivery.Spot.RightsDates[key].from)).toLocaleDateString("en-US")
                            + '(' + key + ' Rights)'
                            + '</li>';
                    }
                }
            }

            if(new Date(vm.delivery.DistributionOrder.flightStart) > today) {
                futureDatesFound += '<li>'
                    + (new Date(vm.delivery.DistributionOrder.flightStart)).toLocaleDateString("en-US")
                    + '(Order Flight Start)'
                    + '</li>';
            }

            if(vm.runStartDate && new Date(vm.runStartDate) > today) {
                futureDatesFound += '<li>'
                    + (new Date(vm.runStartDate)).toLocaleDateString("en-US")
                    + '(Spot Run Start)'
                    + '</li>';
            }

            if(
                new Date(vm.delivery.DistributionOrder.flightStart) > today ||
                (vm.runStartDate && new Date(vm.runStartDate) > today) ||
                latestRightDate > today
            ) {
                // Cose out the list if there is one
                futureDatesFound += '</ul>';
    
                let confirm = vm.$mdDialog
                    .confirm()
                    .title('Would you like to continue?')
                    .htmlContent(
                        "<p>This delivery is not available until the following dates for certain rights.</p><p>Are you sure you want to download it?</p>" + futureDatesFound
                    )
                    .ariaLabel(
                        "This delivery is outside of some or all of its rights windows, are you sure you want to download it?"
                    )
                    .ok('Continue')
                    .cancel('Cancel');
    
                try {
                    await vm.$mdDialog.show(confirm);
                } catch (e) {
                    // User said no
                    return false;
                }
            }

            alias = alias.replace('__', '_');

            vm.NotificationService.showNotificationToast('Download(s) has started.');

            // Build download links
            let endpoint = vm.ResourceEndPointService.assetDownloadEndpoint + '/' + vm.delivery.Spot.id + '/';

            vm.$window.open(
                endpoint +
                    '?' +
                    'assetUuid=' +
                    uuid +
                    '&' +
                    'baseFilename=' +
                    alias +
                    '&' +
                    'authorization=' +
                    vm.loginService.getJwt()
            );
        }

        downloadTrafficAsset() {
            let vm = this;
            if (!vm.assetMap.TRAFFIC || vm.assetMap.TRAFFIC.length < 1) {
                return;
            }

            // Make request to get __sessionKey
            vm.AuthenticationResourceFactory.getSessionKey(
                {},
                function (sessionKey:any) {
                    // Append __sessionKey to request to Node (instead of RE)
                    vm.$window.open(
                        vm.EndPointService.downloadTrafficAssetEndPoint.replace(
                            ':id',
                            vm.delivery.id
                        ) +
                            '?sessionKey=' +
                            sessionKey.data,
                        '_blank'
                    );

                    // NODE: Take __sessionKey and pipe request to RE
                    // NODE: Open request directly into response to stream data back to browser
                },
                function () {
                    // Unable to retrieve the session key, maybe the user is logged out.
                }
            );
            return true;
        }

        async viewMetadataReport() {
            let vm = this;

            // Start handling dates
            let today = new Date();
            let latestRightDate = new Date();
            let futureDatesFound = '<ul>';
            
            // Find the most future right date
            if(vm.delivery.Spot.RightsDates && (vm.delivery.Spot.RightsDates.BROADCAST || vm.delivery.Spot.RightsDates.DIGITAL || vm.delivery.Spot.RightsDates.NEWMEDIA || vm.delivery.Spot.RightsDates.INDUSTRIAL)) {
                for (const [key] of Object.entries(vm.delivery.Spot.RightsDates)) {
                    if(new Date(vm.delivery.Spot.RightsDates[key].from) > latestRightDate) {
                        latestRightDate = new Date(vm.delivery.Spot.RightsDates[key].from);
                    }
                    if(new Date(vm.delivery.Spot.RightsDates[key].from) > today) {
                        futureDatesFound += '<li>'
                            + (new Date(vm.delivery.Spot.RightsDates[key].from)).toLocaleDateString("en-US")
                            + '(' + key + ' Rights)'
                            + '</li>';
                    }
                }
            }

            if(new Date(vm.delivery.DistributionOrder.flightStart) > today) {
                futureDatesFound += '<li>'
                    + (new Date(vm.delivery.DistributionOrder.flightStart)).toLocaleDateString("en-US")
                    + '(Order Flight Start)'
                    + '</li>';
            }

            if(vm.runStartDate && new Date(vm.runStartDate) > today) {
                futureDatesFound += '<li>'
                    + (new Date(vm.runStartDate)).toLocaleDateString("en-US")
                    + '(Spot Run Start)'
                    + '</li>';
            }

            if(
                new Date(vm.delivery.DistributionOrder.flightStart) > today ||
                (vm.runStartDate && new Date(vm.runStartDate) > today) ||
                latestRightDate > today
            ) {
                // Cose out the list if there is one
                futureDatesFound += '</ul>';
    
                let confirm = vm.$mdDialog
                    .confirm()
                    .title('Would you like to continue?')
                    .htmlContent(
                        "<p>This delivery is not available until the following dates for certain rights.</p><p>Are you sure you want to download it?</p>" + futureDatesFound
                    )
                    .ariaLabel(
                        "This delivery is outside of some or all of its rights windows, are you sure you want to download it?"
                    )
                    .ok('Continue')
                    .cancel('Cancel');
    
                try {
                    await vm.$mdDialog.show(confirm);
                } catch (e) {
                    // User said no
                    return false;
                }
            }

            if (vm.assetMap.QC_REPORT_DATA && vm.assetMap.QC_REPORT_DATA.length > 0) {
                return vm.$mdDialog.show({
                    controller: 'MetadataReportController',
                    controllerAs: 'vm',
                    template: require('../view/metadataReportDialog-template.html'),
                    parent: angular.element(document.body),
                    clickOutsideToClose: true,
                    fullscreen: true, // For small screens only
                    resolve: {
                        reportData() {
                            return vm.assetMap.QC_REPORT_DATA[0];
                        },
                    },
                });
            }
        }

        openSystemEvents() {
            let vm = this;

            if (vm.delivery && vm.delivery.id) {
                return vm.$mdDialog.show({
                    controller: 'SystemEventsDialogController',
                    controllerAs: 'vm',
                    template: require('../common/dialogs/systemEventsDialog-template.html'),
                    parent: angular.element(document.body),
                    clickOutsideToClose: true,
                    fullscreen: true,
                    locals: {
                        id: vm.delivery.id,
                        type: 'orderItem',
                    },
                });
            }
        }

        acceptDelivery() {
            let vm = this;

            vm.DestinationResourceFactory.getOperationsDestination(
                { id: vm.delivery.Destination.id },
                {},
                function success(dest:any) {
                    vm.delivery.OperationsDestination = dest.data.opsDest;
                    if (
                        vm.delivery.deliveryStatus === 'WAITING_FOR_ACCEPTANCE' &&
                        vm.delivery.OperationsDestination &&
                        vm.delivery.Spot.airStatus === 'RTA'
                    ) {
                        return vm.$mdDialog
                            .show({
                                controller: OperationsDestinationDialogController,
                                controllerAs: 'vm',
                                template: require('./operationsDestinationDialog-template.html'),
                                parent: angular.element(document.body),
                                clickOutsideToClose: true,
                                fullscreen: true,
                                locals: {
                                    deliveries: [vm.delivery],
                                },
                            })
                            .then(
                                function success(deliveries:any) {
                                    angular.forEach(deliveries, function (delivery) {
                                        delivery[0].Destination = delivery.selectedDest;
                                        delete delivery[0].OperationsDestination;
                                        vm.delivery = delivery[0];
                                    });
                                    vm.DeliveryResourceFactory.save(
                                        { id: vm.delivery.id },
                                        vm.delivery,
                                        function success() {
                                            vm.DeliveryResourceFactory.acceptPromos(
                                                {},
                                                {
                                                    orderItemIds: [vm.delivery.id],
                                                },
                                                function success() {
                                                    vm.NotificationService.showNotificationToast(
                                                        'Spot has been accepted for delivery'
                                                    );
                                                    vm.$state.reload();
                                                },
                                                function failure(err:any) {
                                                    console.log(err);
                                                    vm.NotificationService.showNotificationToast(
                                                        'Spot failed to be accepted for delivery',
                                                        err.data
                                                    );
                                                }
                                            );
                                        },
                                        function failure(err:any) {
                                            console.log(err);
                                        }
                                    );
                                },
                                function canceled() {
                                    //user canceled the dialog, so do nothing
                                }
                            );
                    } else {
                        vm.DeliveryResourceFactory.acceptPromos(
                            {},
                            {
                                orderItemIds: [vm.delivery.id],
                            },
                            function success() {
                                vm.NotificationService.showNotificationToast(
                                    'Spot has been accepted for delivery'
                                );
                                vm.$state.reload();
                            },
                            function failure(err:any) {
                                console.log(err);
                                vm.NotificationService.showNotificationToast(
                                    'Spot failed to be accepted for delivery',
                                    err.data
                                );
                            }
                        );
                    }
                },
                function failure() {
                    //no ops destination, so don't need popup
                    vm.DeliveryResourceFactory.acceptPromos(
                        {},
                        { orderItemIds: [vm.delivery.id] },
                        function success() {
                            vm.NotificationService.showNotificationToast(
                                'Spot has been accepted for delivery'
                            );
                            vm.$state.reload();
                        },
                        function failure(err:any) {
                            console.log(err);
                            vm.NotificationService.showNotificationToast(
                                'Spot failed to be accepted for delivery',
                                err.data
                            );
                        }
                    );
                }
            );
        }

        trafficReview() {
            let vm = this;

            if (vm.delivery.deliveryStatus === 'PARKED') {
                return vm.$mdDialog
                    .show({
                        controller: TrafficReviewDialogController,
                        controllerAs: 'vm',
                        template: require('./trafficReviewDialog-template.html'),
                        parent: angular.element(document.body),
                        clickOutsideToClose: true,
                        locals: {
                            orderItem: vm.delivery,
                        },
                    })
                    .then(
                        function saved(overrideValues:any) {
                            //User closed popup
                            overrideValues.resubmit = true;
                            vm.DeliveryResourceFactory.updateMetadata(
                                {},
                                {
                                    id: vm.delivery.id,
                                    orderItemMetadataOverride: overrideValues,
                                    spotId:vm.delivery.Spot.id,
                                    groupId:vm.delivery.sourceOrderGroupId,
                                    destinationId:vm.delivery.Destination.id,
                                    orderId: vm.delivery.DistributionOrder.id
                                },
                                function success() {
                                    vm.NotificationService.showNotificationToast(
                                        'Traffic Review information has been applied.'
                                    );
                                    vm.$state.reload();
                                },
                                function failure(err:any) {
                                    console.log(err);
                                    vm.NotificationService.showNotificationToast(
                                        'Failed to apply Traffic Review information.',
                                        err.data
                                    );
                                }
                            );
                        },
                        function () {
                            //user cancelled the popup
                        }
                    );
            }
        }

        hasRoles(roles:any) {
            let vm = this;
            return vm.loginService.hasPermission(roles.split(','));
        }

        openThumbnailDialog(thumbnailIndex:any) {
            let vm = this;

            vm.$mdDialog.show({
                controller: 'ThumbnailDialogController',
                controllerAs: 'vm',
                template: require('../view/thumbnailDialog-template.html'),
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                locals: {
                    storyBoard: vm.delivery.Spot.Storyboard.sort(function (a:any, b:any) {
                        return a.imageIndex - b.imageIndex;
                    }).map(function iterator(cur:any) {
                        return cur.Asset.AssetContent.contentUuid;
                    }),
                    thumbnailIndex,
                },
            });
        }

        openMenu($mdMenu:any) {
            $mdMenu.open();
        }

        copySpotProxy() {
            let vm = this;

            return vm.$mdDialog
                .show({
                    controller: 'CopySpotProxyDialogController',
                    controllerAs: 'vm',
                    template: require('../spots/copySpotProxyDialog-template.html'),
                    parent: angular.element(document.body),
                    clickOutsideToClose: true,
                    fullscreen: false, // For small screens only
                    locals: {
                        apiURL: vm.assetMap.apiURL,
                    },
                })
                .then(
                    function () {
                        //User closed popup
                    },
                    function () {
                        //user cancelled the popup
                    }
                );
        }

        calculateDurationDisplay(date1:any, date2:any) {
            let vm = this;

            if (date1 === undefined || date1 == null || date2 === undefined || date2 == null) {
                return '';
            }
            let endDate = moment(date1);
            let startDate = moment(date2);
            let diff = endDate.diff(startDate);

            return vm.$filter('smallTimeDuration')(diff);
        }

        checkTermsAndConditions(uuid:any, type:any, alias:any) {
            let vm = this;

            if (uuid === undefined) {
                return;
            }

            let spotAssetId: any;
            let originalAssetId: any;
            let qtAssetId: any;
            for (let i = 0; i < vm.delivery.Assets.length; i++) {
                if (vm.delivery.Assets[i].assetType === 'ORIGINAL') {
                    originalAssetId = parseInt(vm.delivery.Assets[i].id, 10);
                }
                if (vm.delivery.Assets[i].assetType === 'SPOT') {
                    spotAssetId = parseInt(vm.delivery.Assets[i].id, 10);
                }
                if (vm.delivery.Assets[i].assetType === 'PROXY') {
                    qtAssetId = parseInt(vm.delivery.Assets[i].id, 10);
                }
            }

            let newAssetId = type === 'spot' ? spotAssetId : originalAssetId;

            let createOriginal = vm.$q.defer();
            let createProxy = vm.$q.defer();

            let promises = [];

            if (type === 'original') {
                // Just in case anyone can see the icon for any length of time, this will let them know something IS happening
                vm.loading.original = true;
                // If the original doesn't have a DB entry, tell the dialog to look it up, since it will be created now
                newAssetId = !newAssetId || newAssetId === undefined ? true : newAssetId;

                promises.push(
                    vm.DeliveryResourceFactory.makeDeliveryOriginal(
                        { id: vm.delivery.id },
                        function success() {
                            // It should come in here if it created a new ORIGINAL entry in order_item_asset
                            // OR if an entry already existed for this delivery

                            // We have to stop the loading spinner, or download won't happen
                            vm.loading.original = false;
                            createOriginal.resolve(true);
                        },
                        function failure(err:any) {
                            // Something has gone catastrophically wrong
                            console.log(err);
                            vm.loading.original = false;
                            createOriginal.resolve(true);
                        }
                    ).$promise
                );
            }

            if (type === 'qt') {
                // Just in case anyone can see the icon for any length of time, this will let them know something IS happening
                vm.loading.qt = true;
                // If the original doesn't have a DB entry, tell the dialog to look it up, since it will be created now
                qtAssetId = !qtAssetId || qtAssetId === undefined ? true : qtAssetId;

                promises.push(
                    vm.DeliveryResourceFactory.makeDeliveryProxy(
                        { id: vm.delivery.id },
                        function success() {
                            // It should come in here if it created a new PROXY entry in order_item_asset
                            // OR if an entry already existed for this delivery

                            // We have to stop the loading spinner, or download won't happen
                            vm.loading.qt = false;
                            createProxy.resolve(true);
                        },
                        function failure(err:any) {
                            // Something has gone catastrophically wrong
                            console.log(err);
                            vm.loading.qt = false;
                            createProxy.resolve(true);
                        }
                    ).$promise
                );
            }

            // Generating an original for orderItemAsset table, even if it's not a promo, since they have the same problem
            vm.$q.all(promises).then(function () {
                if (vm.termsAndConditions && !vm.isAdmin) {
                    vm.$mdDialog
                        .show({
                            controller: TCDialogController,
                            controllerAs: 'vm',
                            template: require('./tcDialog-template.html'),
                            parent: angular.element(document.body),
                            clickOutsideToClose: true,
                            fullscreen: true, // For small screens only
                            locals: {
                                assetName: vm.delivery.Spot ? vm.delivery.Spot.isci : 'TRAFFIC',
                                terms: vm.termsAndConditions,
                                deliveredSpot: type === 'spot' ? newAssetId : false,
                                originalSpot: type === 'original' ? newAssetId : false,
                                qtProxy: type === 'qt' ? qtAssetId : false,
                                deliveryId: vm.delivery.id,
                                assetType: [type],
                            },
                        })
                        .then(
                            function accepted() {
                                vm.downloadAsset(uuid, type, alias);
                            },
                            function declined() {
                                //User declined, do not start the download.
                                //Do nothing.
                            }
                        );
                } else {
                    vm.downloadAsset(uuid, type, alias);
                }
            });
        }

        retransmitSpot() {
            let vm = this;

            let expiredString = '<br>This spot cannot be redelivered. </br></p>';

            if (vm.delivery.expiredFlag) {
                expiredString += '<p>&emsp;&emsp;Reason: Delivery has expired.</br></p>';

                let confirm = vm.$mdDialog
                    .alert()
                    .title('Invalid Delivery')
                    .htmlContent(expiredString)
                    .ariaLabel(expiredString)
                    .ok('OK');

                vm.$mdDialog.show(confirm);
            } else {
                vm._retransmitSpot(vm.delivery.id);
            }
        }

        toggleArchive() {
            let vm = this;

            // This will set the flag to the opposite of what it is now.
            let confirmOrNot;

            if (!vm.delivery.archivedFlag) {
                let confirm = vm.$mdDialog
                    .confirm()
                    .title('Would you like to continue?')
                    .textContent(
                        "Archiving an item will remove it from the default view. It will not be deleted but will be excluded in searches unless you select 'Include archives'."
                    )
                    .ariaLabel(
                        "Archiving an item will remove it from the default view. It will not be deleted but will be excluded in searches unless you select 'Include archives'."
                    )
                    .ok('Continue')
                    .cancel('Cancel');

                confirmOrNot = vm.$mdDialog.show(confirm);
            } else {
                confirmOrNot = vm.$q.when(true);
            }

            confirmOrNot.then(
                function () {
                    // User confirmed the archive function
                    vm.delivery.archivedFlag = !vm.delivery.archivedFlag;

                    vm.DeliveryResourceFactory.save({ id: vm.delivery.id }, vm.delivery, () => {});
                },
                function () {
                    // User cancelled the archive function so don't do anything
                }
            );
        }

        updateHouseId() {
            let vm = this;

            return vm.$mdDialog
                .show({
                    controller: UpdateHouseIdDialogController,
                    controllerAs: 'vm',
                    template: require('./updateHouseIdDialog-template.html'),
                    parent: angular.element(document.body),
                    clickOutsideToClose: true,
                    locals: {
                        delivery: vm.delivery,
                    },
                })
                .then(
                    function saved(overrideValues:any) {
                        //User closed popup
                        vm.DeliveryResourceFactory.updateMetadata(
                            {},
                            {
                                id: vm.delivery.id,
                                orderItemMetadataOverride: overrideValues,
                            },
                            function success() {
                                vm.NotificationService.showNotificationToast(
                                    'House ID has been updated.'
                                );
                                vm.$state.reload();
                            },
                            function failure(err:any) {
                                console.log(err);
                                vm.NotificationService.showNotificationToast(
                                    'Failed to update House ID',
                                    err.data
                                );
                            }
                        );
                    },
                    function () {
                        //user cancelled the popup
                    }
                );
        }

        requestCustomization() {
            // Open a dialog (passing delivery details to it)
            let vm = this;

            // Show the customization dialog, all logic will take place inside it
            vm.$mdDialog.show({
                controller: RequestCustomizationDialog.controller,
                controllerAs: 'vm',
                template: RequestCustomizationDialog.template,
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                locals: {
                    delivery: vm.delivery
                },
            });
        }

        /* IMPLEMENTATION : PRIVATE */
        // All private methods should start with '_' in order to distinguish them
        _setCurrentAsset() {
            let vm = this;

            let spotAsset = null;
            let trafficAsset = null;
            vm.spotDeliveryFilename = '';

            if (vm.delivery && vm.delivery.Assets) {
                vm.delivery.Assets.forEach(function (asset:any) {
                    if (asset.assetType === 'SPOT' || asset.assetType === 'ORIGINAL') {
                        spotAsset = asset;
                    } else if (asset.assetType === 'TRAFFIC') {
                        trafficAsset = asset;
                    }

                    // Issue: COM-14323
                    // It appears that non-broadcast assets do not presently get named, which means this value is never set properly for them
                    if (asset.assetType === 'SPOT' && asset.Asset && asset.Asset.name) {
                        let filenameFull = asset.Asset.name;
                        vm.spotDeliveryFilename = filenameFull.replace(/\.[^/.]+$/, '');
                    }
                });
            }

            // If there isn't a spot asset, this is a traffic-only order
            vm.orderItemAsset = spotAsset != null ? spotAsset : trafficAsset;
        }

        _setCurrentAssetDelivery() {
            let vm = this;

            if (vm.delivery && vm.orderItemAsset && vm.orderItemAsset.Deliveries) {
                vm.orderItemAsset.Deliveries.sort(function (a:any, b:any) {
                    if (a.date_created > b.date_created) {
                        return -1;
                    }
                    if (a.date_created < b.date_created) {
                        return 1;
                    }
                    return 0;
                });

                vm.orderItemAssetDelivery = vm.orderItemAsset.Deliveries[0];
                if (vm.orderItemAsset.Deliveries.length > 0) {
                    vm.deliveryDuration = vm.calculateDurationDisplay(
                        vm.orderItemAssetDelivery.deliveryEndDate,
                        vm.orderItemAssetDelivery.actualDeliveryStartDate
                    );
                } else {
                    vm.deliveryDuration = 0;
                }
            }
        }

        _loadData(loadThumbs:any) {
            let vm = this;

            loadThumbs = loadThumbs === undefined ? false : loadThumbs;

            vm.canDownload = {
                deliveredSpot: false,
                originalSpot: false,
                mezzanine: false,
                quicktimeProxy: false,
                mp3: false,
                wmv: false,
                proxy: false,
                slate: false,
                qcReport: false,
                metadata: false,
                traffic: false,
            };

            vm.DeliveryResourceFactory.get({ id: vm.$stateParams.id }, function (ret:any) {
                vm.delivery = ret.data;
                vm.delivery.statusBar = vm.statusService.readOrderItemStatus(vm.delivery);
                vm._setCurrentAsset();
                vm._setCurrentAssetDelivery();

                // set formatted display fields
                if (vm.delivery.Spot) {
                    vm.uploadStartDate = vm.delivery.Spot.uploadStartDate
                        ? moment(vm.delivery.Spot.uploadStartDate)
                              .tz(moment.tz.guess())
                              .format('MM/DD/YY h:mm:ss A z')
                        : '';
                    vm.runStartDate = vm.delivery.Spot.runStartDate
                        ? moment(vm.delivery.Spot.runStartDate)
                              .tz(moment.tz.guess())
                              .format('MM/DD/YY')
                        : '';
                    vm.runEndDate = vm.delivery.Spot.runEndDate
                        ? moment(vm.delivery.Spot.runEndDate)
                              .tz(moment.tz.guess())
                              .format('MM/DD/YY')
                        : '';

                    // If the rights dates object has values, we need to convert those suckers to an object we can
                    // assign models to in the template
                    if (vm.delivery.Spot.RightsDates && vm.delivery.Spot.RightsDates.length > 0) {
                        vm.showMultiRightsDates = true;

                        let tempDateHolder:any = {};

                        for (let i = 0; i < vm.delivery.Spot.RightsDates.length; i++) {
                            tempDateHolder[vm.delivery.Spot.RightsDates[i].type] = {
                                from: vm.delivery.Spot.RightsDates[i].validFromDate,
                                to: vm.delivery.Spot.RightsDates[i].validToDate,
                            };
                        }

                        // Convert rights dates into an object
                        vm.delivery.Spot.RightsDates = {
                            BROADCAST: {
                                spot: vm.delivery.Spot.id,
                                from:
                                    tempDateHolder.BROADCAST && tempDateHolder.BROADCAST.from
                                        ? moment(tempDateHolder.BROADCAST.from)
                                              .tz(moment.tz.guess())
                                              .format('MM/DD/YY')
                                        : null,
                                to:
                                    tempDateHolder.BROADCAST && tempDateHolder.BROADCAST.to
                                        ? moment(tempDateHolder.BROADCAST.to)
                                              .tz(moment.tz.guess())
                                              .format('MM/DD/YY')
                                        : null,
                            },
                            DIGITAL: {
                                spot: vm.delivery.Spot.id,
                                from:
                                    tempDateHolder.DIGITAL && tempDateHolder.DIGITAL.from
                                        ? moment(tempDateHolder.DIGITAL.from)
                                              .tz(moment.tz.guess())
                                              .format('MM/DD/YY')
                                        : null,
                                to:
                                    tempDateHolder.DIGITAL && tempDateHolder.DIGITAL.to
                                        ? moment(tempDateHolder.DIGITAL.to)
                                              .tz(moment.tz.guess())
                                              .format('MM/DD/YY')
                                        : null,
                            },
                            NEWMEDIA: {
                                spot: vm.delivery.Spot.id,
                                from:
                                    tempDateHolder.NEWMEDIA && tempDateHolder.NEWMEDIA.from
                                        ? moment(tempDateHolder.NEWMEDIA.from)
                                              .tz(moment.tz.guess())
                                              .format('MM/DD/YY')
                                        : null,
                                to:
                                    tempDateHolder.NEWMEDIA && tempDateHolder.NEWMEDIA.to
                                        ? moment(tempDateHolder.NEWMEDIA.to)
                                              .tz(moment.tz.guess())
                                              .format('MM/DD/YY')
                                        : null,
                            },
                            INDUSTRIAL: {
                                spot: vm.delivery.Spot.id,
                                from:
                                    tempDateHolder.INDUSTRIAL && tempDateHolder.INDUSTRIAL.from
                                        ? moment(tempDateHolder.INDUSTRIAL.from)
                                              .tz(moment.tz.guess())
                                              .format('MM/DD/YY')
                                        : null,
                                to:
                                    tempDateHolder.INDUSTRIAL && tempDateHolder.INDUSTRIAL.to
                                        ? moment(tempDateHolder.INDUSTRIAL.to)
                                              .tz(moment.tz.guess())
                                              .format('MM/DD/YY')
                                        : null,
                            },
                        };
                    }
                }

                vm.delivery.Destination.ReceiverAccount.XferClients.forEach(function (client:any) {
                    if (
                        client.xferClientHostName &&
                        (client.xferClientType === 'PRIMARY' ||
                            client.xferClientType === 'REDUNDANT')
                    ) {
                        vm.hostnames += client.xferClientHostName + '; ';
                    }
                });

                if (
                    vm.delivery.Spot &&
                    vm.delivery.Spot.duration > vm.delivery.Spot.expectedDuration
                ) {
                    vm.deliveriesStatuses.COMPLETED.status = 'Long Duration Delivered';
                }

                // Set the loading flag
                vm.gettingAssetUuids = true;
                // Retrieve the assetMap
                vm.DeliveryResourceFactory.getAssetMap(
                    { id: vm.delivery.id },
                    function success(assetMap:any) {
                        vm.assetMap = assetMap.data;
                        vm.gettingAssetUuids = false;

                        vm._setDownloadPermissions();

                        if (
                            vm.delivery.Spot &&
                            vm.delivery.Spot.html5TranscodeStatus === 'FAILED' &&
                            vm.delivery.Spot.format !== 'RADIO'
                        ) {
                            vm.displayError = true;
                            vm.assetMap.HTML5_PROXY = [];
                        } else if (
                            vm.assetMap &&
                            vm.assetMap.HTML5_PROXY &&
                            vm.assetMap.HTML5_PROXY[0] &&
                            vm.delivery.Spot.format !== 'RADIO' &&
                            !bowser.msie &&
                            !bowser.msedge &&
                            !bowser.safari
                        ) {
                            vm.AssetResourceFactory.getVideoAsset(
                                {
                                    uuid: vm.assetMap.HTML5_PROXY[0],
                                },
                                {},
                                function success(asset:any) {
                                    vm.proxyAsset = URL.createObjectURL(asset.data.data); // IE10+
                                    vm.proxyType = 'video/webm';
                                },
                                function failure(err:any) {
                                    console.log(err);
                                }
                            );
                        } else if (
                            vm.assetMap &&
                            vm.assetMap.FLV_PROXY &&
                            vm.assetMap.FLV_PROXY[0] &&
                            vm.delivery.Spot.format !== 'RADIO' &&
                            (bowser.msie || bowser.msedge || bowser.safari)
                        ) {
                            vm.AssetResourceFactory.getVideoAsset(
                                { uuid: vm.assetMap.FLV_PROXY[0] },
                                {},
                                function success(asset:any) {
                                    vm.proxyAsset = URL.createObjectURL(asset.data.data); // IE10+
                                    vm.proxyType = 'video/mp4';
                                },
                                function failure(err:any) {
                                    console.log(err);
                                }
                            );
                        } else if (vm.delivery.Spot.format === 'RADIO') {
                            // RADIO SPOTS
                            vm.AssetResourceFactory.getAudioAsset(
                                { uuid: vm.assetMap.MEZZANINE[0] },
                                function success(asset:any) {
                                    vm.proxyAsset = URL.createObjectURL(asset.data.data); // IE10+
                                    vm.proxyType = 'audio/mp3';
                                },
                                function failure(err:any) {
                                    console.log(err);
                                }
                            );
                        }

                        if (loadThumbs) {
                            vm._loadThumbnails();
                        }
                    },
                    function failure(err:any) {
                        vm.gettingAssetUuids = false;
                        console.log(err);
                    }
                );
            });
        }

        expandThumbnails(showPane:boolean) {
            let vm = this;

            vm.showThumbnailStrip = showPane;

            if (showPane) {
                if (vm.storyBoard.length === 0) {
                    vm._loadThumbnails();
                }
            }
        }

        _loadThumbnails() {
            let vm = this;

            if (!vm.delivery.Spot || !vm.delivery.Spot.Storyboard) {
                vm.storyBoard = [];
                return;
            }

            let storyboard = angular.copy(vm.delivery.Spot.Storyboard);

            if (vm.assetMap.SLATE && vm.assetMap.SLATE.length > 0) {
                // Unshift is probably not necessary here anymore
                storyboard.unshift({
                    imageIndex: -100,
                    Asset: {
                        AssetContent: {
                            contentUuid: vm.assetMap.SLATE[0],
                        },
                    },
                });
            }

            // We still need all these in the Storyboard so we can load the correct thumbnails on click
            vm.delivery.Spot.Storyboard = angular.copy(storyboard);

            vm.$q
                .all(
                    storyboard
                        .sort(function (a:any, b:any) {
                            return a.imageIndex - b.imageIndex;
                        })
                        .map(function iterator(cur:any) {
                            return vm.AssetResourceFactory.getImageAsset(
                                {
                                    uuid: cur.Asset.AssetContent.contentUuid,
                                },
                                {}
                            ).$promise;
                        })
                )
                .then(
                    function success(storyboardImages:any) {
                        vm.storyBoard = storyboardImages.map(function (img:any) {
                            let blob = new Blob([img.data], {
                                type: img.config['Content-Type'],
                            });
                            return URL.createObjectURL(blob);
                        });
                    },
                    function failure(err:any) {
                        console.log(err);
                        vm.storyBoard = [];
                    }
                );
        }

        _setDownloadPermissions() {
            let vm = this;

            let canEdit = vm.hasRoles('orderitem.modify');
            let canView = vm.hasRoles('orderitem.view');
            let isSuperUser = vm.hasRoles('superuser');

            vm.canDownload.deliveredSpot =
                !vm.delivery.expiredFlag &&
                vm.delivery.Spot &&
                vm.delivery.Spot.format !== 'RADIO' &&
                canEdit;
            vm.canDownload.originalSpot =
                vm.delivery.Spot &&
                !vm.delivery.Spot.expiredFlag &&
                (canEdit || vm._sessionData.accountType === 'FULFILLMENT') &&
                vm.delivery.deliveryStatus !== 'WAITING_FOR_ACCEPTANCE';
            vm.canDownload.mezzanine =
                vm.delivery.Spot &&
                !vm.delivery.Spot.expiredFlag &&
                isSuperUser &&
                vm.delivery.deliveryStatus !== 'WAITING_FOR_ACCEPTANCE';
            vm.canDownload.quicktimeProxy =
                vm.delivery.Spot &&
                !vm.delivery.Spot.expiredFlag &&
                canView && 
                canEdit &&
                vm.delivery.Spot.format !== 'RADIO';
            vm.canDownload.mp3 =
                vm.delivery.Spot &&
                !vm.delivery.Spot.expiredFlag &&
                canView &&
                canEdit &&
                vm.delivery.Spot.format === 'RADIO';
            vm.canDownload.wmv =
                vm.delivery.Spot &&
                !vm.delivery.Spot.expiredFlag &&
                canView &&
                canEdit &&
                vm.delivery.Spot.format !== 'RADIO';
            vm.canDownload.proxy =
                vm.delivery.Spot &&
                !vm.delivery.Spot.expiredFlag &&
                canView &&
                canEdit &&
                vm.delivery.Spot.format !== 'RADIO';
            vm.canDownload.slate =
                canEdit &&
                vm.delivery.Spot &&
                vm.delivery.Destination.separateSlateFlag &&
                vm.delivery.Spot.format !== 'RADIO';
            vm.canDownload.qcReport = canEdit;
            vm.canDownload.metadata = canEdit;
            vm.canDownload.traffic =
                canView && canEdit && vm.delivery.deliveryStatus !== 'WAITING_FOR_ACCEPTANCE';
        }

        _retransmitSpot(deliveryId:any) {
            let vm = this;

            vm.DeliveryResourceFactory.retransmitSpots(
                {},
                { orderItemIds: [deliveryId] },
                function success() {
                    vm.NotificationService.showNotificationToast(
                        'Spot has been submitted for redelivery'
                    );
                    vm.$state.reload();
                },
                function failure(err:any) {
                    console.log(err);
                    vm.NotificationService.showNotificationToast(
                        'Spot failed to be submitted for delivery',
                        err.data
                    );
                }
            );
        }

        $onInit() {
            let vm = this;

            vm.$timeout(function () {
                vm.$rootScope.deliveryDetailForm = vm.$scope.deliveryDetailForm;
            });

            $('.sidebar').addClass('hidden');
            if (vm.$scope.home) {
                vm.$scope.home.showTgl = false;
            }
            vm.TermsAndConditionsResourceFactory.findOne(
                { id: vm._sessionData.accountId, eventType: 'download' },
                {},
                function (tc:any) {
                    vm.termsAndConditions =
                        tc.data && tc.data.termsAndConditions ? tc.data.termsAndConditions : null;
                },
                function notFound() {
                    vm.termsAndConditions = null;
                }
            );
            vm._loadData(false);
        }
    },
    controllerAs: 'vm',
};
